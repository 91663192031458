import { interceptors, use } from '@nucoro/investor-area'
import { getRoutes } from '@/models/onboarding'

const routes = [
  {
    path: '/assessment',
    name: 'assessment',
    redirect: to => ({ name: 'assessment-goal', params: to.params })
  },
  {
    path: '/assessment/goal',
    name: 'assessment-goal',
    component: () => import(/* webpackChunkName: "assessment-goal" */ '@/views/onboarding/goal'),
    props: { nextStep: 'assessment-time-horizon', hideBack: true }
  },
  ...getRoutes({ from: 'time-horizon', to: 'risk-result', prefix: 'assessment' }) || [],
  {
    path: '/assessment/risk-result',
    name: 'assessment-risk-result',
    component: () => import(/* webpackChunkName: "assessment-risk-result" */ '@/views/assessment/result'),
    props: { previousStep: 'assessment-goal' }
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.requiresAuthentication,
    interceptors.fetchClient,
    interceptors.fetchSettings,
    interceptors.requiresEmailVerified,
    interceptors.managesRetakeAssessment,
    interceptors.initOnboardingState,
    interceptors.checkOnboardingStep,
    to => use.onboardingProgress.updateProgressFromRoute(to.name)
  ]
}))
