export default {
  components: [
    'NcButtonHighlighted',
    'NcInputSlider',
    'NcModal'
  ],
  widgets: [
    'NcStepperQuestion'
  ],
  layouts: [
    'NcOnboardingLayout'
  ],
  views: [
    'NcAccountLocked',
    'NcAssessmentResult',
    'NcCreateAccount',
    'NcDashboard',
    'NcEmailConfirmation',
    'NcForcedMfa',
    'NcImpersonate',
    'NcLogin',
    'NcLoginMfa',
    'NcNotFound',
    'NcOnboardingAddress',
    'NcOnboardingAssetsHeld',
    'NcOnboardingAvsNumber',
    'NcOnboardingContract',
    'NcOnboardingDateOfBirth',
    'NcOnboardingFinal',
    'NcOnboardingGoal',
    'NcOnboardingKids',
    'NcOnboardingLossTolerance',
    'NcOnboardingMaritalStatus',
    'NcOnboardingModelPortfolio',
    'NcOnboardingName',
    'NcOnboardingOutcome',
    'NcOnboardingPhone',
    'NcOnboardingPortfolioSummary',
    'NcOnboardingRiskResult',
    'NcOnboardingTradingMethod',
    'NcOnboardingUploadId',
    'NcPasswordForgotten',
    'NcPasswordForgottenSuccess',
    'NcPasswordReset',
    'NcProfile',
    'NcProfileAbout',
    'NcProfileChangePassword',
    'NcProfileDocuments',
    'NcProfileInvestor',
    'NcProfileLegal',
    'NcProfileMe',
    'NcProfilePortfolios',
    'NcProfilePrivacy',
    'NcProfileSecurity',
    'NcReports',
    'NcSetPassword',
    'NcTokenExpired',
    'NcTransfers',
    'NcWelcome'
  ]
}
