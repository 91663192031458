import { interceptors, use } from '@nucoro/investor-area'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/login'),
    meta: { transition: 'none' }
  },
  {
    path: '/login-mfa',
    name: 'login-mfa',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/login-mfa')
  },
  {
    path: '/forced-mfa',
    name: 'forced-mfa',
    component: () => import(/* webpackChunkName: "forced-mfa" */ '@/views/auth/forced-mfa')
  },
  {
    path: '/password-forgotten',
    name: 'password-forgotten',
    component: () => import(/* webpackChunkName: "password-forgotten" */ '@/views/auth/password-forgotten')
  },
  {
    path: '/reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '@/views/auth/password-reset')
  },
  {
    path: '/password-forgotten-success',
    name: 'password-forgotten-success',
    component: () => import(
      /* webpackChunkName: "password-forgotten-success" */ '@/views/auth/password-forgotten-success'
    )
  },
  {
    path: '/token-expired',
    name: 'token-expired',
    component: () => import(/* webpackChunkName: "token-expired" */ '@/views/auth/token-expired')
  },
  {
    path: '/account-locked',
    name: 'account-locked',
    component: () => import(/* webpackChunkName: "account-locked" */ '@/views/auth/account-locked')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import(/* webpackChunkName: "create-account" */ '@/views/auth/create-account'),
    meta: { transition: 'none' }
  },
  {
    path: '/email-confirmation/:uuid/:token',
    name: 'email-confirmation',
    component: () => import(/* webpackChunkName: "email-confirmation" */ '@/views/auth/email-confirmation')
  },
  {
    path: '/set-password/:uuid?/:token?',
    name: 'set-password',
    component: () => import(/* webpackChunkName: "set-password" */ '@/views/auth/set-password')
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found')
  },
  {
    path: '/impersonate',
    name: 'impersonate',
    component: () => import(/* webpackChunkName: "impersonate" */ '@/views/auth/impersonate')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.impersonate,
    to => { if (!['forced-mfa', 'email-confirmation'].includes(to?.name)) use.auth.logout() }
  ]
}))
