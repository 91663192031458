import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'

export default createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior (_, __, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(savedPosition || { top: 0 })
      }, 500)
    })
  }
})
