import { interceptors } from '@nucoro/investor-area'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports'),
    props: true
  },
  {
    path: '/transfers',
    name: 'transfers',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/transfers')
  },
  {
    path: '/account',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/profile'),
    redirect: { name: 'profile-me' },
    children: [
      {
        path: 'investor',
        name: 'profile-investor',
        component: () => import(/* webpackChunkName: "profile-me" */ '@/views/profile/profile-investor')
      },
      {
        path: 'me',
        name: 'profile-me',
        component: () => import(/* webpackChunkName: "profile-me" */ '@/views/profile/profile-me')
      },
      {
        path: 'documents',
        name: 'profile-documents',
        component: () => import(/* webpackChunkName: "profile-documents" */ '@/views/profile/profile-documents')
      },
      {
        path: 'legal',
        name: 'profile-legal',
        component: () => import(/* webpackChunkName: "profile-legal" */ '@/views/profile/profile-legal')
      },
      {
        path: 'portfolios',
        name: 'profile-portfolios',
        component: () => import(/* webpackChunkName: "profile-portfolios" */ '@/views/profile/profile-portfolios')
      },
      {
        path: 'privacy',
        name: 'profile-privacy',
        component: () => import(
          /* webpackChunkName: "profile-privacy" */ '@/views/profile/profile-privacy'
        )
      },
      {
        path: 'security',
        name: 'profile-security',
        component: () => import(/* webpackChunkName: "profile-security" */ '@/views/profile/profile-security')
      },
      {
        path: 'about',
        name: 'profile-about',
        component: () => import(/* webpackChunkName: "profile-about" */ '@/views/profile/profile-about')
      }
    ]
  },
  {
    path: '/profile-change-password',
    name: 'profile-change-password',
    component: () => import(
      /* webpackChunkName: "profile-change-password" */ '@/views/profile/profile-change-password'
    )
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '@/views/welcome')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.requiresAuthentication,
    interceptors.fetchClient,
    interceptors.fetchSettings,
    interceptors.fetchPortfolios,
    interceptors.requiresEmailVerified,
    interceptors.requiresOnboardingCompleted,
    interceptors.removesPendingAssessments
  ]
}))
