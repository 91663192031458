import { createApp } from 'vue'
import NucoroInvestorArea from '@nucoro/investor-area'
import router from './router'
import config from './config'
import elements from './elements'
import messages from '@/i18n/messages'
import { init as initOnboarding } from '@/models/onboarding'

import '@/assets/index.css'
import App from './App.vue'

createApp(App)
  .use(NucoroInvestorArea, {
    config, i18n: { locale: config.app.locale, messages }, elements, router
  })
  .use(router)
  .mount('#app')

// customize onboarding steps
initOnboarding()
