import publicRoutes from './public'
import privateRoutes from './private'
import onboardingRoutes from './onboarding'
import retakeAssessmentRoutes from './retake-assessment'

export default [
  ...publicRoutes,
  ...privateRoutes,
  ...onboardingRoutes,
  ...retakeAssessmentRoutes,
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found'
  }
]
