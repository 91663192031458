import { interceptors, use } from '@nucoro/investor-area'
import { getRoutes } from '@/models/onboarding'

const routes = [
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: '/onboarding/name'
  },
  // stage 1
  ...getRoutes({ from: 'name', to: 'model-portfolio' }),
  // stage 2
  ...getRoutes({ from: 'model-portfolio', to: 'phone', meta: { configuresPortfolio: true } }),
  // stage 3
  ...getRoutes({ from: 'phone', to: 'contract' }),
  {
    path: '/onboarding/contract',
    name: 'onboarding-contract',
    component: () => import(/* webpackChunkName: "onboarding-contract" */ '@/views/onboarding/contract'),
    props: { nextStep: 'onboarding-final' },
    meta: { checkAssessmentFilled: true }
  },
  {
    path: '/onboarding/final',
    name: 'onboarding-final',
    component: () => import(/* webpackChunkName: "onboarding-final" */ '@/views/onboarding/final')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.requiresAuthentication,
    interceptors.fetchClient,
    interceptors.requiresOnboardingPending,
    interceptors.fetchSettings,
    interceptors.requiresEmailVerified,
    interceptors.fetchPortfolioData,
    interceptors.managesAssessmentOutdated,
    interceptors.initOnboardingState,
    interceptors.checkOnboardingStep,
    interceptors.requiresAssessmentFilled,
    to => { if (to.name !== 'onboarding-final') use.onboardingProgress.updateProgressFromRoute(to.name) }
  ]
}))
